import { FC, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
// Types
import { TPatentData } from "Types";
// Components
import { Button, Textbox, Paginator, Dropdown, LoadingStatusIndicator } from 'Components';
import { ColumnSelectionButton } from './ColumnSelectionButton/ColumnSelectionButton';
import { ExportButton } from './ExportButton/ExportButton';
import { TableDataCell } from './TableDataCell/TableDataCell';
// Enums
import { LoadingStatusEnum } from 'Enums';
// Hooks
import { usePagination } from 'Hooks';
// Helpers
import { LogHelperSingleton } from 'Helpers';
// Styles
import styles from "./search.module.scss";

type TChatProps = {
    patentDataResults: TPatentData[],
    handleGetSimilarResults: (selectedItemForGettingSimilars: TPatentData) => void,
    isLoading: boolean,
    handleSortResults: (column: string, sortType: "asc" | "desc") => void,
    selectedTab: string,
    searchText: string,
}

export const Search: FC<TChatProps> = ({ patentDataResults, handleGetSimilarResults, isLoading, handleSortResults, selectedTab, searchText }) => {
    // Memo
    const columnOptions = useMemo(() => {
        const allColumnOptions = ["Link", "Relevancy", "Title", "Abstract", "Claims", "Date"];
        if (selectedTab === "science") {
            // Remove Claims column on Science Documents
            allColumnOptions.splice(4, 1);
        }
        return allColumnOptions;
    }, [selectedTab]);
    const sortableColumns = useMemo(() => (
        [{
            value: "score",
            text: "Relevancy",
        }, {
            value: "date",
            text: "Date",
        }]
    ), []);

    // State
    const [shownColumns, setShownColumns] = useState<string[]>(columnOptions);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [isAllOptionSelected, setIsAllOptionSelected] = useState<boolean>(false);
    const [sortType, setSortType] = useState<"asc" | "desc" | undefined>(undefined);
    const [sortByColumn, setSortByColumn] = useState<string>("");

    const { setCurrentPageNumber, setTotalCount, currentPageNumber, paginationIndicator, resetPagination } = usePagination(rowsPerPage, patentDataResults.length);

    const totalFilteredResults = useMemo(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return patentDataResults.filter((result) => {
            return ['title', 'abstract', 'claims', 'link', 'date', 'score'].some(field =>
                result[field as keyof TPatentData] && result[field as keyof TPatentData].toLowerCase().includes(lowerCaseSearchTerm)
            );
        });
    }, [patentDataResults, searchTerm]);

    const totalPageCount = totalFilteredResults.length / rowsPerPage < 1 ? 1 : Math.ceil(totalFilteredResults.length / rowsPerPage);

    useEffect(() => {
        setTotalCount(totalFilteredResults.length);
    }, [totalFilteredResults, setCurrentPageNumber, setTotalCount]);

    useEffect(() => {
        setShownColumns(columnOptions);
    }, [columnOptions, selectedTab]);

    useEffect(() => {
        // if total page count is less than current page number
        if (totalPageCount < currentPageNumber) {
            // set current page number to total page count
            setCurrentPageNumber(totalPageCount);
        }
    }, [totalPageCount, currentPageNumber, setCurrentPageNumber]);

    useEffect(() => {
        // if a new search is run or get similar results is clicked
        if (isLoading) {
            // then set selected page to 1
            resetPagination();
        }
    }, [isLoading, resetPagination]);

    const onColumnSelectionChange = (selectedColumns: string[]) => {
        setShownColumns(selectedColumns);
    }

    const resultsOnTheCurrentPage = useMemo(() => {
        // based on current page and rows per page
        const startIndex = (currentPageNumber - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;

        const currentResults = !searchTerm ? patentDataResults : totalFilteredResults;
        const resultsInCurrentPage = currentResults.slice(startIndex, endIndex);
        return resultsInCurrentPage;
    }, [patentDataResults, searchTerm, currentPageNumber, rowsPerPage, totalFilteredResults]);

    const onResultCountOnAPageChange = (option: number | string) => {
        const isAllOptionSelected = option === "All";
        const count = isAllOptionSelected ? totalFilteredResults.length : option as number;
        setRowsPerPage(count);
        setIsAllOptionSelected(isAllOptionSelected);
    }

    const onPageChange = (newPageNumber: number) => {
        setCurrentPageNumber(newPageNumber);
    }

    const onSortIconClick = (column: string) => {
        const newSortType = !sortType || sortType === "desc" ? "asc" : "desc";
        setSortType(newSortType);
        setSortByColumn(column);
        const columnToSortBy = column === "Relevancy" ? "score" : column === "Date" ? "date" : column;
        handleSortResults(columnToSortBy, newSortType);
    }

    const onSearchInputChange = (searchText: string) => {
        setSearchTerm(searchText);
        onPageChange(1);
    }

    const handleLinkClick = (link: string) => {
        // log
        LogHelperSingleton.logWithProperties("ResultClick", {
            "SearchTab": `${selectedTab}`,
            "SearchSubTab": "documents",
            "SearchText": `${searchText}`,
            "Link": `${link}`
        });
    }

    // Render
    if (isLoading) {
        return (
            <div className={styles.container}>
                <div className={styles.loadingContainer}>
                    <LoadingStatusIndicator size={64} status={LoadingStatusEnum.Loading} />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {patentDataResults.length > 0 && (
                <div className={styles.dataContainer}>
                    <div className={styles.filterContainer}>
                        <Textbox
                            extraClassName={styles.searchTextbox}
                            placeholder="Search"
                            showEmptyInputCrossIcon={!!searchTerm}
                            onChange={onSearchInputChange}
                            onEmptyInputButtonClickHandler={() => onSearchInputChange("")}
                            value={searchTerm}
                            id="filterSearch"
                        />
                        <div className={styles.toolbarButtons}>
                            <ColumnSelectionButton
                                extraClassNames={{ container: styles.columnSelectionButtonContainer }}
                                columnOptions={columnOptions}
                                onSelectionChange={onColumnSelectionChange}
                            />
                            <ExportButton />
                        </div>
                    </div>
                    <table id="js-documentsTable" className={styles.dataTable}>
                        <thead >
                            <tr>
                                {shownColumns.map((column: string) => {
                                    if (column === "") {
                                        return null;
                                    }
                                    return (
                                        <th key={column.toLowerCase()}>
                                            <div className={styles.columnTitleContainer}>
                                                {column}
                                                {sortableColumns.map(column => column.text).includes(column) && (
                                                    <FontAwesomeIcon className={`${styles.sortIcon} ${sortByColumn === column ? styles.active : ""}`} icon={sortByColumn === column ? (sortType === "asc" ? faSortUp : faSortDown) : faSort} onClick={() => { onSortIconClick(column); }} />
                                                )}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {resultsOnTheCurrentPage.length > 0 ? resultsOnTheCurrentPage.map((result: TPatentData) => (
                                <tr key={result.id}>
                                    {shownColumns.includes("Link") && <td>
                                        <span onClick={() => handleLinkClick(`${result.link}`)}>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                id={result.id}
                                                href={`${result.link}`}
                                            >[Access]
                                            </a>
                                        </span>
                                    </td>}
                                    {shownColumns.includes("Relevancy") && <td><div className={styles.scoreColumnHeader}>{`${result.score}%`}</div></td>}
                                    {shownColumns.includes("Title") && <td>{result.title}
                                        <Button
                                            extraClassName={styles.similarsButton}
                                            title="Similars"
                                            buttonType="tertiary"
                                            onClick={() => handleGetSimilarResults(result)}
                                        />
                                    </td>}
                                    {shownColumns.includes("Abstract") && (
                                        <TableDataCell text={result.abstract} />
                                    )}
                                    {shownColumns.includes("Claims") && (
                                        <TableDataCell text={result.claims} />
                                    )}
                                    {shownColumns.includes("Date") && <td><div className={styles.dateColumnHeader}>{result.date}</div></td>}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={shownColumns.length} className={styles.noResultsFound}>
                                        No matching records found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {resultsOnTheCurrentPage.length > 0 && (
                        <div className={styles.footer}>
                            <div className={styles.paginationDetails}>
                                <span>{paginationIndicator}</span>
                                <Dropdown
                                    onOptionSelect={(option) => onResultCountOnAPageChange(option)}
                                    options={[5, 10, 20, "All"]}
                                    selectedOption={isAllOptionSelected ? "All" : rowsPerPage}
                                    extraClassNames={{ dropdownButton: styles.rowsPerPageDropdownButton }}
                                />
                                <span> rows per page</span>
                            </div>
                            {!isAllOptionSelected && totalPageCount !== 1 && (
                                <Paginator
                                    onPageChange={onPageChange}
                                    pageCount={totalPageCount}
                                    selectedPage={currentPageNumber}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
